<template>
  <section class="section has-bunya-max-width">
    <subpage-header
      :primary-action="primaryAction"
      :secondary-actions="secondaryActions"
      title="Tasks"
      v-on:task-create="taskCreate"
    ></subpage-header>
    <div class="subpage-information-area-margin">
      <div class="box bunya-box is-hidden-mobile">
        <b-table
          :checkable="false"
          :loading="isLoading"
          :striped="true"
          :hoverable="true"
          default-sort="task"
          selectable
          @select="editTask"
          :data="tasks">
          <template slot-scope="props">
            <b-table-column class="has-no-head-mobile is-image-cell" width="80px">
              <div class="flex-circle-avatar bunya-green" v-if="props.row.completed_at">
                <icon icon="check" stroke="#FFFFFF"></icon>
              </div>
              <div class="flex-circle-avatar grey" v-if="!props.row.completed_at">
                <icon icon="check" stroke="#FCFCFC"></icon>
              </div>
            </b-table-column>
            <b-table-column label="Task" field="title" sortable>
              <h3>{{ props.row.title }}</h3>
            </b-table-column>
            <b-table-column label="Client" field="client" sortable>
              <h5 class="light normal">{{ props.row.client.name }}</h5>
            </b-table-column>
            <b-table-column label="Created By" field="created_by" sortable>
              <h5 class="light normal">{{ props.row.user.display_name }}</h5>
            </b-table-column>
            <b-table-column label="Created">
              <small class="has-text-grey is-abbr-like" :title="props.row.created_at">{{ props.row.created_at | moment('Do MMM, YYYY') }}</small>
            </b-table-column>
          </template>
          <section class="section" slot="empty">
            <div class="box bunya-box no-shadow empty-state large">
              <div class="no-content">
                <img class="large" src="../assets/emptyStates/empty_tasks.svg">
                <h3 class="large">Tasks</h3>
                <h5 class="large">You don't have any tasks yet.</h5>
                <a class="button is-primary">Create Task</a>
              </div>
            </div>
          </section>
        </b-table>
      </div>
    </div>
  </section>
</template>

<script>

import SubpageHeader from '@/components/SubpageHeader'
import Icon from '@/components/Icons/Icon'

export default {
  name: 'Clients',
  components: {
    SubpageHeader,
    Icon
  },
  computed: {
    showOnboarding () {
      return this.isLoading === true || this.tasks.length === 0
    },
    tasks () {
      return this.$store.getters['tasks/all']
    }
  },
  data () {
    return {
      isLoading: false,
      primaryAction: null,
      secondaryActions: null
    }
  },
  methods: {
    taskCreate () {
      this.$router.push({ name: 'task-create' })
    },
    editTask () {
      /**
       * Note implemented.
       */
    }
  },
  mounted () {
    this.isLoading = true
    this.$store.dispatch('tasks/fetch', { withCompleted: true })
      .then(() => {
        this.isLoading = false
      })
      .catch(e => {
        this.isLoading = false
        this.$buefy.toast.open({
          message: 'Unable to fetch tasks.',
          type: 'is-danger'
        })
      })
  }
}
</script>
